.about-container {
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  color: #333;

  .about-header {
      text-align: center;
      margin-bottom: 40px;

      h1 {
          font-size: 36px;
          color: #2c3e50;
          font-weight: 700;
      }
  }

  .about-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      padding: 40px 20px;

      .about-image {
          flex: 1;

          img {
              width: 100%;
              border-radius: 10px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
      }

      .about-text {
          flex: 2;

          p {
              font-size: 18px;
              line-height: 1.6;
              margin-bottom: 20px;
          }
      }
  }
}
