.transactions {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f6f9;

    .header {
        text-align: center;
        margin-bottom: 40px;

        h1 {
            font-size: 36px;
            color: #2c3e50;
            font-weight: 700;
        }
    }

    .transactions-section {
        max-width: 1200px;
        margin: auto;
        background: white;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;

        h2 {
            margin-bottom: 15px;
            color: #333;
        }

        table {
            width: 100%;
            border-collapse: collapse;

            th,
            td {
                border: 1px solid #ddd;
                padding: 10px;
                text-align: center;
            }
        }

        p {
            margin: 5px 0;
        }
    }

    .transactions-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
    }

    .transactions-table th,
    .transactions-table td {
        padding: 10px;
        border: 1px solid #ddd;
        text-align: left;
    }

    .transactions-table th {
        background-color: #f4f4f4;
    }

    .transactions-table tbody tr:hover {
        background-color: #f9f9f9;
    }

    .fab-container {
        position: fixed;
        bottom: 20px;
        right: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      
      .fab {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
}