.dashboard {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f4f6f9;

  .summary-section,
  .dti-section {
      background: white;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;

      h2 {
          margin-bottom: 15px;
          color: #333;
      }

      table {
          width: 100%;
          border-collapse: collapse;

          th,
          td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: center;
          }
      }

      p {
          margin: 5px 0;
      }
  }

  .charts-container {
      display: flex;
      gap: 20px;
      margin-bottom: 30px;

      .chart-card {
          background: white;
          border-radius: 10px;
          padding: 20px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          flex: 1;

          h3 {
              margin-bottom: 15px;
              color: #333;
          }
      }
  }

  @media (max-width: 768px) {
      .charts-container {
          flex-direction: column;
      }
  }
}
