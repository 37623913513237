body, html {
    height: 100%;
    margin: 0;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #a2c2e5, #f3f4f6);
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .login-card {
    padding: 40px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    background-color: #ffffff;
    border: none;
  
    .form-label {
      font-weight: 500;
    }
  
    .form-control {
      border-radius: 8px;
      border: 1px solid #ccc;
      padding: 12px;
      margin-bottom: 15px;
  
      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
      }
    }
  
    .btn {
      border-radius: 8px;
      padding: 12px;
      background-color: #007bff;
      border: none;
      color: #fff;
      font-weight: 600;
      transition: background-color 0.3s ease, transform 0.2s ease;
  
      &:hover {
        background-color: #0056b3;
        transform: translateY(-2px);
      }
  
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
      }
    }
  }
  
  .imgSize {
    width: 35%;
  }
  
  .card-title {
    color: #333;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  